'use client'

import Image from 'next/image'
import NextLink from 'next/link'
import { useRef } from 'react'
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Grid,
  GridItem,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
  useDisclosure
} from '@/components/Chakra'
import Logo from '@/assets/images/logo.webp'
import Navbar from '@/components/Navbar'
import Icon from '@/components/Icon'

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)

  return (
    <Box as="header" width="full" maxW="1376px" bgColor="gray.700" m="0 auto" position="sticky" top={0} zIndex={2}>
      <Grid templateRows={{ base: "70px 1fr", md: "120px 1fr" }} templateColumns="repeat(12, 1fr)" gridColumnGap={2} borderBottom={{ base: "1px solid", md: "none" }} borderColor="gray.600">
        <GridItem colSpan={{ base: 7, md: 3 }} gridRowStart={{ base: 1 }} gridColumnStart={{ base: 2, md: 2 }} alignSelf="center">
          <LinkBox>
            <HStack spacing={1.5}>
              <Box as="picture">
                <Image src={Logo} width={27} height={27} alt="Logo Pase al Medio" />
              </Box>
              <Text fontWeight={700} fontSize="lg" color="white">
                <LinkOverlay as={NextLink} href="/">PASEALMEDIO</LinkOverlay>
              </Text>
            </HStack>
          </LinkBox>
        </GridItem>
        
        <GridItem display={{ base: "block", md: "none" }} colSpan={{ base: 2 }} gridRowStart={{ base: 1 }} gridColumnStart={{ base: 11 }} alignSelf="center">
          <IconButton aria-label='Menu mobile' icon={<Icon name="menu" size={32} />} onClick={onOpen} variant="link" color="white" />
        </GridItem>

        <GridItem display={{ base: "none", md: "block" }} colSpan={{ md: 6, lg: 5, xl: 4 }} gridRowStart={{ md: 1 }} gridColumnStart={{ md: 6, lg: 7, xl: 8 }} alignSelf="center">
          <Navbar />
        </GridItem>
      </Grid>

      <Drawer isOpen={isOpen} size="full" placement="right" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent bgColor="gray.700">
          <DrawerCloseButton color="white" size="lg" boxSize="52px" />
          <DrawerBody p={0}>
            <Navbar />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default Header
