import NextLink from 'next/link'
import { usePathname } from 'next/navigation'
import { Box, Flex, Link } from '@/components/Chakra'

const Navbar = () => {
  const pathname = usePathname()

  const home = pathname === '/'
  const tables = pathname === '/tablas'
  const fixtures = pathname === '/fixtures'
  const results = pathname === '/resultados'
  const transfers = pathname === '/transferencias'

  return (
    <Box as="nav" height={{ base: "full", md: "auto" }}>
      <Flex as="ol" height={{ base: "full", md: "auto" }} listStyleType="none" flexDir={["column", null, "row"]} justifyContent={{ base: "center", md: "space-between" }}>
        <Box
          as="li"
          py={{ base: 4, md: 0 }}
          textAlign="center"
          fontWeight={home ? 600 : 400}
          color={home ? "yellow.400" : "white"}
        >
          <Link as={NextLink} href="/" fontSize={{ base: "xl", md: "md" }} lineHeight={10} _hover={{ color: "yellow.400" }}>Inicio</Link>
        </Box>
        <Box
          as="li"
          py={{ base: 4, md: 0 }}
          textAlign="center"
          fontWeight={tables ? 600 : 400}
          color={tables ? "yellow.400" : "white"}
        >
          <Link as={NextLink} href="/tablas" fontSize={{ base: "xl", md: "md" }} lineHeight={10} _hover={{ color: "yellow.400" }}>Tablas</Link>
        </Box>
        <Box
          as="li"
          py={{ base: 4, md: 0 }}
          textAlign="center"
          fontWeight={fixtures ? 600 : 400}
          color={fixtures ? "yellow.400" : "white"}
        >
          <Link as={NextLink} href="/fixtures" fontSize={{ base: "xl", md: "md" }} lineHeight={10} _hover={{ color: "yellow.400" }}>Fixtures</Link>
        </Box>
        <Box
          as="li"
          py={{ base: 4, md: 0 }}
          textAlign="center"
          fontWeight={results ? 600 : 400}
          color={results ? "yellow.400" : "white"}
        >
          <Link as={NextLink} href="/resultados" fontSize={{ base: "xl", md: "md" }} lineHeight={10} _hover={{ color: "yellow.400" }}>Resultados</Link>
        </Box>
        <Box
          as="li"
          py={{ base: 4, md: 0 }}
          textAlign="center"
          fontWeight={transfers ? 600 : 400}
          color={transfers ? "yellow.400" : "white"}
        >
          <Link as={NextLink} href="/transferencias" fontSize={{ base: "xl", md: "md" }} lineHeight={10} _hover={{ color: "yellow.400" }}>Transferencias</Link>
        </Box>
      </Flex>
    </Box>
  )
}

export default Navbar
