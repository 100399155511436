'use client'

/**
 * Required file to fix "Nextjs RSC error".
 * https://github.com/chakra-ui/chakra-ui/issues/7878
 */

export type { BoxProps, UseModalProps } from '@chakra-ui/react'

export {
  Box,
  Button,
  Center,
  ChakraProvider,
  Circle,
  Container,
  Collapse,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  extendTheme,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  Link,
  LinkBox,
  LinkOverlay,
  List,
  ListIcon,
  ListItem,
  Select,
  Stack,
  Square,
  Table,
  TableContainer,
  TableCaption,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  theme,
  Tr,
  VisuallyHidden,
  useDisclosure,
  useMediaQuery,
  useToast
} from '@chakra-ui/react'